import { createRouter, createWebHistory } from "vue-router";
import {
  requiresAuthButNotAuth,
  requiresUnauthButAuth,
  toDefaultRequiresUnauth,
  toDefaultRequiresAuth,
  toLastRoute,
  accessDenied,
  restrictDossierPhaseAccess,
} from "@/utils/router";
import { checkIfHasPermittedRole } from "@/utils/auth-roles";

import store from "@/store";

import routes from "./routes";

const mode = import.meta.env.MODE;

const router = createRouter({
  history: createWebHistory(mode === "development" ? "admin" : "/"),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (requiresAuthButNotAuth(to)) {
    return next(toDefaultRequiresUnauth);
  } else if (requiresUnauthButAuth(to)) {
    return next(toDefaultRequiresAuth);
  } else if (to.meta.requiresAuth) {
    if (!store.getters["auth/isAgency"]) {
      accessDenied();
      // ???: ..
      store.dispatch("auth/deauthenticate");
      return next(toDefaultRequiresUnauth);
    }

    if (
      to.meta.permittedRoles &&
      !checkIfHasPermittedRole(store.getters["auth/currentUserRoles"], to.meta.permittedRoles)
    ) {
      return next(toLastRoute(from));
    }

    if (await restrictDossierPhaseAccess(to)) {
      accessDenied();
      return next(toLastRoute(from));
    }
  }

  next();
});

export default router;
