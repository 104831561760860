import { userRoles } from "@/constants/users";
import { commonRoutes } from "@/utils/router";
export default [
  ...commonRoutes({ abPhasesIncluded: true, trashedDocumentsIncluded: true }),
  {
    path: "/configurations",
    name: "route.configurations",
    component: () => {
      return import(
        /* webpackChunkName: "configurations" */ "@/vue/views/admin/ConfigurationsView.vue"
      );
    },
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
  {
    path: "/configurations/employes",
    name: "route.configurations.employees",
    redirect: { name: "route.configurations.employees.list" },
    component: () =>
      import(
        /* webpackChunkName: "configurations-employees" */ "@/vue/views/admin/configuration/employees/EmployeesView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
    children: [
      {
        path: "",
        name: "route.configurations.employees.list",
        component: () =>
          import(
            /* webpackChunkName: "configurations-employees" */ "@/vue/views/admin/configuration/employees/EmployeesListView.vue"
          ),
      },
      {
        path: "creer",
        name: "route.configurations.employees.add",
        component: () =>
          import(
            /* webpackChunkName: "configurations-employees" */ "@/vue/views/admin/configuration/employees/AddEmployeeView.vue"
          ),
      },
      {
        path: ":id/modifier",
        name: "route.configurations.employees.edit",
        component: () =>
          import(
            /* webpackChunkName: "configurations-employees" */ "@/vue/views/admin/configuration/employees/EditEmployeeView.vue"
          ),
      },
    ],
  },
  {
    path: "/configurations/apporteurs",
    name: "route.configurations.partners",
    redirect: { name: "route.configurations.partners.list" },
    component: () =>
      import(
        /* webpackChunkName: "configurations-partners" */ "@/vue/views/admin/configuration/partners/PartnersView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [
        userRoles.ADMIN,
        userRoles.EMPLOYEE,
        userRoles.RESPONSIBLE,
        userRoles.RESPONSIBLE_ASSIGNER,
      ],
    },
    children: [
      {
        path: "",
        name: "route.configurations.partners.list",
        component: () =>
          import(
            /* webpackChunkName: "configurations-partners" */ "@/vue/views/admin/configuration/partners/PartnersListView.vue"
          ),
      },
      {
        path: "creer",
        name: "route.configurations.partners.add",
        component: () =>
          import(
            /* webpackChunkName: "configurations-partners" */ "@/vue/views/admin/configuration/partners/AddPartnerView.vue"
          ),
        meta: {
          permittedRoles: [userRoles.ADMIN],
        },
      },
      {
        path: ":id/modifier",
        name: "route.configurations.partners.edit",
        component: () =>
          import(
            /* webpackChunkName: "configurations-partners" */ "@/vue/views/admin/configuration/partners/EditPartnerView.vue"
          ),
      },
    ],
  },
  {
    path: "/configurations/assureurs",
    name: "route.configurations.insurers",
    component: () =>
      import(
        /* webpackChunkName: "configurations-insurers" */ "@/vue/views/admin/configuration/insurer/InsurerListView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
  {
    path: "/configurations/assureurs/creer",
    name: "route.configurations.insurerNew",
    component: () =>
      import(
        /* webpackChunkName: "configurations-insurers" */ "@/vue/views/admin/configuration/insurer/InsurerNewView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
  {
    path: "/configurations/assureurs/:id/modifier",
    name: "route.configurations.insurerDetails",
    component: () =>
      import(
        /* webpackChunkName: "configurations-insurers" */ "@/vue/views/admin/configuration/insurer/InsurerDetailsView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
  {
    path: "/configurations/leads",
    name: "route.configurations.leads",
    component: () =>
      import(
        /* webpackChunkName: "configurations-insurers" */ "@/vue/views/admin/configuration/LeadsView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configurations/comptabilite",
    name: "route.configurations.dossierExport",
    component: () =>
      import(
        /* webpackChunkName: "configurations-dossierExport" */ "@/vue/views/admin/configuration/DossierExportsView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
  {
    path: "/configurations/emails-desabonnes",
    name: "route.configurations.unsubscribedEmails",
    component: () =>
      import(
        /* webpackChunkName: "configurations-unsubscribedEmails" */ "@/vue/views/admin/configuration/UnsubscribedEmailsView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },

  {
    path: "/statistiques",
    name: "route.statistics",
    component: () => {
      return import(/* webpackChunkName: "statistics" */ "@/vue/views/admin/StatisticsView.vue");
    },
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },

  {
    path: "/statistiques/dossiers",
    name: "route.statistics.dossiers",
    component: () =>
      import(
        /* webpackChunkName: "statistics-dossiers" */ "@/vue/components/statistics/DossiersView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statistiques/apporteurs",
    name: "route.statistics.partners",
    component: () =>
      import(
        /* webpackChunkName: "statistics-partners" */ "@/vue/components/statistics/partner/PartnersView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
  {
    path: "/statistiques/employes",
    name: "route.statistics.employees",
    component: () =>
      import(
        /* webpackChunkName: "statistics-employees" */ "@/vue/components/statistics/employees/EmployeesView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statistiques/delais",
    name: "route.statistics.insurer",
    component: () =>
      import(
        /* webpackChunkName: "statistics-insurer" */ "@/vue/components/statistics/InsurersView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },

  {
    path: "/entreprises",
    name: "route.entreprises",
    component: () =>
      import(
        /* webpackChunkName: "entreprises" */ "@/vue/views/admin/configuration/EntreprisesView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [
        userRoles.ADMIN,
        userRoles.EMPLOYEE,
        userRoles.RESPONSIBLE,
        userRoles.RESPONSIBLE_ASSIGNER,
      ],
    },
  },

  {
    path: "/configurations/companies",
    name: "route.configurations.companies",
    component: () =>
      import(
        /* webpackChunkName: "configurations-companies" */ "@/vue/views/admin/configuration/company/CompanyListView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [
        userRoles.ADMIN,
        userRoles.RESPONSIBLE,
        userRoles.RESPONSIBLE_ASSIGNER,
        userRoles.EMPLOYEE,
      ],
    },
  },

  {
    path: "/configurations/general",
    name: "route.configurations.general",
    component: () => {
      return import(
        /* webpackChunkName: "configurations-general" */ "@/vue/views/admin/configuration/ConfigurationsGeneralView.vue"
      );
    },
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },

  {
    path: "/configurations/backup",
    name: "route.configurations.backup",
    component: () =>
      import(
        /* webpackChunkName: "configurations-backup" */ "@/vue/views/admin/configuration/BackupListView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },

  {
    path: "/configurations/archivage-dossier",
    name: "route.configurations.archivedDossier",
    component: () =>
      import(
        /* webpackChunkName: "configurations-archivedDossier" */ "@/vue/views/admin/configuration/DossierArchivedView.vue"
      ),
    meta: {
      requiresAuth: true,
      permittedRoles: [
        userRoles.ADMIN,
        userRoles.RESPONSIBLE,
        userRoles.RESPONSIBLE_ASSIGNER,
        userRoles.EMPLOYEE,
      ],
    },
  },
  {
    path: "/onedrive/authorize-success",
    name: "route.authorize.success",
    component: function () {
      return import(/* webpackChunkName: "admin" */ "@/vue/views/AuthorizeSuccessView.vue");
    },
    meta: {
      requiresAuth: true,
      permittedRoles: [userRoles.ADMIN],
    },
  },
];
